<template>
    <div>
        <b-container>
            <b-row>
                <b-col md="5" order-md="2" class="mb-4">
                    <img src="@/assets/images/kitchen-design-modern-contemporary.jpg" class="img-fluid" style="box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.12); border-radius: 5px">
                </b-col>
                <b-col md="7" order-md="1">
                    <h3>Nöjda kunder sedan 2007.</h3>
                    <p>
                        Vi på Rato AB har varit verksamma sedan 2007 och strävar alltid efter att våra kunder skall vara nöjda med vårt resultat.<br>
                        <br>
                        Vi utför arbeten inom all typ av bygg och fastighetsskötsel, vi tar oss an både riktigt stora projekt men även mindre servicearbeten.<br>
                        <br>
                        Du kan antingen kontakta oss via frågeformuläret, maila eller slå oss en signal så löser vi ditt problem.<br>
                        <br>
                        Tel: <a href="tel:+46734444003">+46 73 444 4003</a><br>
                        <br>
                        <b-button class="default-button" to="/contact">Kontakta Oss</b-button>
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "AboutGallery"
    }
</script>

<style scoped>

</style>
