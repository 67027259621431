<template>
    <div>
        <div class="gallery">
            <hero
                    type="carousel"
                    :timeout="5000"
                    :dark-background="false"
                    :images="imageList"
            />

            <headline
                    title="Referenser"
                    body="Vi strävar alltid efter att våra kunder ska få det absolut bästa resultatet."
                    class="mb-5 mt-5"
            />

            <about-gallery class="mt-5 pt-5 mb-5"/>
        </div>
    </div>
</template>

<script>
    import Hero from "../components/Hero";
    import Headline from "../components/Headline";
    import AboutGallery from "../components/Gallery/AboutGallery";
    import axios from "axios";
    import config from "config";

    export default {
        name: "Gallery",
        components: {AboutGallery, Headline, Hero},
        data () {
            return {
                imageList: []
            }
        },
        mounted () {
            window.scrollTo(0, 0);

            axios.get(`${config.apiUrl}/carousel/gallery`)
                .then(response => {
                    this.imageList.length = 0;

                    response.data.forEach(e => {
                        this.imageList.push({
                            'url': `https://api.ratoab.se${e.img_path}`,
                            'title': e.img_title,
                            'description': e.img_caption
                        });
                    })
                });

        }
    }
</script>

<style scoped>

</style>
